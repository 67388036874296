import clsx from 'clsx';

type AppButtonProps = {
  text: string | JSX.Element;
  onClick?: Function;
  className?: string;
  disabled?: boolean;
  variant?: 'sm';
  type?: 'button' | 'submit' | 'reset';
  style?: React.CSSProperties;
};

export const AppButton = ({
  text,
  onClick,
  className,
  disabled,
  variant,
  type = 'button',
  style,
}: AppButtonProps) => (
  <button
    disabled={disabled}
    type={type}
    className={clsx(`btn ${className}`, {
      'btn-sm': variant === 'sm',
    })}
    onClick={onClick as any}
    style={style}
  >
    {text}
  </button>
);
