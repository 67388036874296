import React from 'react';
import icons, {IconNames} from '../icons-config/icons';
import {getLayout} from '../../layout/core';

type Props = {
  className?: string;
  iconType?: 'duotone' | 'solid' | 'outline';
  iconName: IconNames;
  color?: string;
  size?: string | number;
};

const KTIcon: React.FC<Props> = ({className = '', iconType, iconName, size, color}) => {
  if (!iconType) {
    iconType = getLayout().main?.iconType;
  }

  return (
    <i
      className={`ki-${iconType} ki-${iconName}${className && ' ' + className}`}
      style={{
        color: color,
        fontSize: size,
      }}
    >
      {iconType === 'duotone' &&
        [...Array(icons[iconName])].map((e, i) => {
          return (
            <span
              key={`${iconType}-${iconName}-${className}-path-${i + 1}`}
              className={`path${i + 1}`}
            ></span>
          );
        })}
    </i>
  );
};

export {KTIcon};
