import { useField, useFormikContext } from 'formik';
import { CustomCheckboxProps, CustomCheckbox } from '../../form/CustomCheckbox';
import { FormikFieldProps } from '../FormikField';

export type FormikCheckboxProps = Omit<CustomCheckboxProps, 'onChange'> & FormikFieldProps & {isSingle?: boolean};

export const FormikCheckbox = (props: FormikCheckboxProps) => {
  const { setFieldValue, values } = useFormikContext<Record<string, any>>();
  const [field] = useField(props);

  const handleChange = (event) => {
    if (props.isSingle) {
      setFieldValue(props.name, event.target.checked);
      return;
    }
    const set = new Set(values[props.name]);
    if (event.target.checked) {
      set.add(props.value);
    } else {
      set.delete(props.value);
    }
    setFieldValue(props.name, [...(set as any)]);
  };

  return (
    <CustomCheckbox
      {...field}
      {...props}
      onChange={handleChange}
      checked={ props.isSingle?  values[props.name] : values[props.name].includes(props.value)}
    />
  );
};
