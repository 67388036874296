import {useField} from 'formik';
import {CustomInput, CustomInputProps} from '../../form/CustomInput';
import {FormikFieldProps} from '../FormikField';
import {useInputActions} from '../useInputActions';

export type FormikInputProps = CustomInputProps & FormikFieldProps;

export const FormikInput = ({dependsOn, onDependencyChange, ...props}: FormikInputProps) => {
  const {actionManagedProps} = useInputActions({dependsOn, onDependencyChange, name: props.name});
  const [field, {error}] = useField(props);
  const value = field.value || '';

  return (
    <div className='w-100 position-relative'>
      <CustomInput {...field} {...props} {...actionManagedProps} error={error} value={value} />
    </div>
  );
};
