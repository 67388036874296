import {useFormikContext} from 'formik';
import React, {useMemo, useState} from 'react';
import {FormikFieldProps} from './FormikField';

export type InputActions = {
  clear: () => void;
  isDisable: (disabled: boolean) => void;
};

export type UseInputActionsProps = {
  dependsOn?: FormikFieldProps['dependsOn'];
  onDependencyChange?: FormikFieldProps['onDependencyChange'];
  name: string;
};

export const useInputActions = ({
  dependsOn = [],
  onDependencyChange,
  name,
}: UseInputActionsProps) => {
  const {values, setFieldValue} = useFormikContext<Record<string, any>>();
  const [actionManagedProps, setActionManagedProps] = useState<Record<string, any>>({});

  // Track of changes made in our dependency list
  const dependencyValuesObject = dependsOn.reduce((acc, dep) => {
    acc[dep] = values[dep];
    return acc;
  }, {});
  const dependencyValues = JSON.stringify(dependencyValuesObject);

  const inputActions = useMemo(
    () => ({
      clear: () => setFieldValue(name, ''),
      isDisable: (disabled) => {
        setActionManagedProps((p) => ({...p, disabled}));
      },
    }),
    [name, setFieldValue]
  ) as InputActions;

  React.useEffect(() => {
    if (onDependencyChange) {
      const _values = JSON.parse(dependencyValues);
      onDependencyChange({..._values}, inputActions);
    }
    // onDependencyChange is not memoized for convenience, so, this line is required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencyValues]);

  return {actionManagedProps};
};
