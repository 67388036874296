import {useCallback} from 'react';
import {DEFAULT_NAMESPACE} from '../constants/default-namespace';
import {useIntl} from 'react-intl';

const useCustomTranslation = (nameSpace: string = DEFAULT_NAMESPACE) => {
  const intl = useIntl();

  const getTranslation = useCallback(
    (id: string) => {
      return intl.formatMessage({id});
    },
    [intl]
  );

  return {
    getTranslation,
  };
};

export default useCustomTranslation;
