import {ROUTE_UNAUTHORIZED} from './users.constants';
import {PermissionDictionaryType, AssignedPermissionListType} from './users.models';

export const userHasPermission = (
  p: keyof PermissionDictionaryType,
  permissions: PermissionDictionaryType
) => {
  return permissions['full:access'] || !!permissions[p];
};

export const createPermissionDictionary = (permissionList: AssignedPermissionListType) => {
  return permissionList.reduce((res, p) => {
    res[p.permission_name] = true;
    return res;
  }, {}) as PermissionDictionaryType;
};

export const getDefaultRoute = (permissions: PermissionDictionaryType) => {
  if (permissions['full:access']) {
    return '/dashboard';
  }

  if (permissions['read:payment-section']) {
    return '/pagos';
  }

  if (permissions['read:loan-section']) {
    return '/prestamos';
  }

  if (permissions['read:stadistic-section']) {
    return '/dashboard';
  }

  if (permissions['read:client-section']) {
    return '/clientes';
  }

  if (permissions['read:user-section']) {
    return '/usuarios';
  }

  if (permissions['read:segment-section']) {
    return '/rutas';
  }

  if (permissions['read:template-section']) {
    return '/plantillas';
  }

  if (permissions['read:cash-section']) {
    return '/cajas';
  }

  if (permissions['read:wallet-section']) {
    return '/carteras';
  }

  if (permissions['read:expense-section']) {
    return '/gastos';
  }

  if (permissions['read:note-credit-debit-section']) {
    return '/notas';
  }

  if (permissions['read:report-loan-section']) {
    return '/informe-prestamos';
  }

  if (permissions['read:report-payment-section']) {
    return '/informe-pagos';
  }

  if (permissions['read:report-cash-section']) {
    return '/informe-cajas';
  }

  if (permissions['read:report-wallet-section']) {
    return '/informe-carteras';
  }

  if (permissions['read:report-segment-section']) {
    return '/informe-rutas';
  }

  if (permissions['read:report-client-section']) {
    return '/informe-clientes';
  }

  if (permissions['read:report-statement-section']) {
    return '/informe-estado-cuenta';
  }

  return ROUTE_UNAUTHORIZED;
};
