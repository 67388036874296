import React from 'react';
import {PopoverColorPicker, PopoverColorPickerProps} from '../PopoverColorPicker';
import {CustomControlError} from './CustomControlError';

export type CustomPopoverColorPickerProps = {
  name: string;
  error?: string;
} & PopoverColorPickerProps;

const CustomPopoverColorPicker = ({error, name, ...props}: CustomPopoverColorPickerProps) => {
  return (
    <div className='position-relative'>
      {!!error && <CustomControlError name={name} error={error} />}
      <PopoverColorPicker
        {...props}
        previewStyle={
          error
            ? {boxShadow: '0 0 0 1px var(--bs-danger), inset 0 0 0 1px var(--bs-danger)'}
            : undefined
        }
      />
    </div>
  );
};

export default CustomPopoverColorPicker;
