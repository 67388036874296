import {useField, useFormikContext} from 'formik';
import {CustomCheckbox, CustomCheckboxProps} from '../../form/CustomCheckbox';
import {FormikFieldProps} from '../FormikField';

export type FormikRadioProps = Omit<CustomCheckboxProps, 'onChange'> &
  FormikFieldProps & {uncheckable?: boolean};

export const FormikRadio = (props: FormikRadioProps) => {
  const {setFieldValue, values} = useFormikContext<Record<string, any>>();
  const [field] = useField(props);

  const handleChange = (event) => {
    if (event.target.checked) {
      setFieldValue(props.name, props.value);
    }
  };

  const handleClick = (event) => {
    if (event.target.checked && props.uncheckable && props.value === values[props.name]) {
      return setFieldValue(props.name, '');
    }
  };

  return (
    <CustomCheckbox
      {...field}
      {...props}
      onChange={handleChange}
      onClick={handleClick}
      checked={values[props.name] === props.value}
      isRadio
    />
  );
};
