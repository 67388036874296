import {useCallback, useRef, useState} from 'react';
import {HexColorPicker} from 'react-colorful';
import styled from 'styled-components';
import useClickOutside from '../utils/hooks/useClickOutside';

const Container = styled.div`
  position: relative;
  .swatch {
    width: 41.64px;
    height: 41.64px;
    border-radius: 8px;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .popover {
    position: absolute;
    top: 45px;
    right: 0;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .picker__swatches {
    display: flex;
    padding: 12px;
    flex-wrap: wrap;
  }

  .picker__swatch {
    width: 24px;
    height: 24px;
    margin: 4px;
    border: none;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }
`;

const DEFAULT_PRESET_COLORS = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#2196F3',
  '#4CAF50',
  '#FFC107',
  '#FF9800',
  '#795548',
  '#00ffff',
  '#ff00ff',
];

export type PopoverColorPickerProps = {
  color: string;
  onChange: (arg0: string) => void;
  presetColors?: string[];
  previewStyle?: React.CSSProperties;
  size?: number;
};

export const PopoverColorPicker = ({
  color,
  onChange,
  presetColors = DEFAULT_PRESET_COLORS,
  previewStyle,
  size,
}: PopoverColorPickerProps) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <Container>
      <div
        className='swatch'
        style={{
          backgroundColor: color,
          ...(previewStyle ? {...previewStyle} : {}),
          ...(size ? {width: size, height: size} : {}),
        }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className='popover' ref={popover as any}>
          <HexColorPicker color={color} onChange={onChange} />
          <div className='picker__swatches'>
            {presetColors.map((presetColor) => (
              <button
                key={presetColor}
                type='button'
                className='picker__swatch'
                style={{background: presetColor}}
                onClick={() => onChange(presetColor)}
              />
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};
