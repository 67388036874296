import {useCallback, useEffect, useRef, useState} from 'react';
import {Overlay, OverlayProps, Popover} from 'react-bootstrap';
import useClickOutside from '../utils/hooks/useClickOutside';
import styled from 'styled-components';

type CustomPopoverProps = {
  TogglerComponent?: ({onClick}: {onClick: any}) => JSX.Element;
  children: ({close}: {close: Function}) => JSX.Element;
  placement: OverlayProps['placement'];
  hideArrow?: boolean;
  containerPadding?: number;
  isParentContainerScrollable?: boolean;
  scrollableParentSelector?: string;
  display?: boolean;
};

const StyledPopover = styled(Popover)`
  &.no-arrow {
    .popover-arrow {
      display: none !important;
    }
  }
`;

export const CustomPopover = ({
  TogglerComponent,
  children,
  placement,
  hideArrow,
  containerPadding = 20,
  isParentContainerScrollable,
  scrollableParentSelector,
  display,
}: CustomPopoverProps) => {
  const [show, setShow] = useState(false);
  const targetRef = useRef<any>(null);
  const popoverRef = useRef<any>(null);

  const toggle = () => {
    setShow((prevShow) => !prevShow);
  };

  const close = useCallback(() => setShow(false), []);
  useClickOutside(popoverRef, close);

  const checkVisibility = () => {
    if (targetRef.current && isParentContainerScrollable && scrollableParentSelector) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const container = targetRef.current.closest(scrollableParentSelector);
      const containerRect = container.getBoundingClientRect();

      // Check if the target element is within the visible bounds of the container
      const isVisible =
        targetRect.top >= containerRect.top &&
        targetRect.bottom <= containerRect.bottom &&
        targetRect.left >= containerRect.left &&
        targetRect.right <= containerRect.right;

      if (!isVisible) {
        setShow(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkVisibility, true);
    return () => window.removeEventListener('scroll', checkVisibility, true);
  }, []);

  return (
    <div ref={targetRef}>
      {!!TogglerComponent && <TogglerComponent onClick={toggle} />}
      {(show || display) && (
        <Overlay show={true} target={targetRef.current} placement={placement}>
          <StyledPopover
            id='popover-contained'
            style={{
              maxWidth: 300,
            }}
            className={hideArrow ? 'no-arrow' : ''}
          >
            <Popover.Body style={{maxWidth: 300, padding: containerPadding}} ref={popoverRef}>
              {children({close})}
            </Popover.Body>
          </StyledPopover>
        </Overlay>
      )}
    </div>
  );
};
