import { FieldInputProps } from 'formik';
import { InputActions } from './useInputActions';
import { ObjectPaths } from '../../utils/types';
import { FormikInput, FormikInputProps } from './components/FormikInput';
import { FormikCheckbox, FormikCheckboxProps } from './components/FormikCheckbox';
import { FormikTextArea, FormikTextAreaProps } from './components/FormikTextArea';
import { FormikDatePicker, FormikDatePickerProps } from './components/FormikDatePicker';
import FormikSelect, { FormikSelectProps } from './components/FormikSelect';
import { FormikFileInput, FormikFileInputProps } from '../form/FormikFileInput';
import {
  FormikPopoverColorPicker,
  FormikPopoverColorPickerProps,
} from './components/FormikPopoverColorPicker';
import { FormikRadio, FormikRadioProps } from './components/FormikRadio';

export type FormikFieldProps = Omit<FieldInputProps<any>, 'onBlur' | 'onChange' | 'value'> & {
  dependsOn?: string[];
  onDependencyChange?: (formValues: Record<string, any>, actions: InputActions) => void;
};

type TypedName<T> = {
  name: ObjectPaths<T>;
};

// ---------------------
// Typed implementations
// ---------------------

// Input
function FormikInputWrapper<T>({ name, ...props }: TypedName<T> & FormikInputProps) {
  return <FormikInput name={name as string} {...props} />;
}
const TypedInput = <T,>() => FormikInputWrapper<T>;

// Checkbox
function FormikCheckboxWrapper<T>({ name, ...props }: TypedName<T> & FormikCheckboxProps) {
  return <FormikCheckbox name={name as string} {...props} />;
}
const TypedCheckbox = <T,>() => FormikCheckboxWrapper<T>;

function FormikRadioWrapper<T>({ name, ...props }: TypedName<T> & FormikRadioProps) {
  return <FormikRadio name={name as string} {...props} />;
}
const TypedRadio = <T,>() => FormikRadioWrapper<T>;

// Textarea
function FormikTextAreaWrapper<T>({ name, ...props }: TypedName<T> & FormikTextAreaProps) {
  return <FormikTextArea name={name as string} {...props} />;
}
const TypedTextArea = <T,>() => FormikTextAreaWrapper<T>;

// DatePicker
function FormikDatePickerWrapper<T>({ name, ...props }: TypedName<T> & FormikDatePickerProps) {
  return <FormikDatePicker name={name as string} {...props} />;
}
const TypedDatePicker = <T,>() => FormikDatePickerWrapper<T>;

// Select
function FormikSelectWrapper<T>({ name, ...props }: TypedName<T> & FormikSelectProps) {
  return <FormikSelect name={name as string} {...props} />;
}
const TypedSelect = <T,>() => FormikSelectWrapper<T>;

// FileInput
function FormikFileInputWrapper<T>({ name, ...props }: TypedName<T> & FormikFileInputProps) {
  return <FormikFileInput name={name as string} {...props} />;
}
const TypedFileInput = <T,>() => FormikFileInputWrapper<T>;

function FormikPopoverColorPickerWrapper<T>({
  name,
  ...props
}: TypedName<T> & FormikPopoverColorPickerProps) {
  return <FormikPopoverColorPicker name={name as string} {...props} />;
}
const TypedPopoverColorPicker = <T,>() => FormikPopoverColorPickerWrapper<T>;

export const getTypedFormikComponents = <T,>(referenceObject: T) => {
  return {
    FormikInput: TypedInput<T>(),
    FormikCheckbox: TypedCheckbox<T>(),
    FormikTextArea: TypedTextArea<T>(),
    FormikDatePicker: TypedDatePicker<T>(),
    FormikSelect: TypedSelect<T>(),
    FormikFileInput: TypedFileInput<T>(),
    FormikPopoverColorPicker: TypedPopoverColorPicker<T>(),
    FormikRadio: TypedRadio<T>(),
  };
};
