import { AxiosResponse } from 'axios';
import {Response} from '../_metronic/helpers';
import { DeletedRecord } from '../models';
import TranslateWithoutHook from '../modules/translation/helpers/translate-without-hook';

export const extractDataFromResponse = (d: Response<any>) => d.data;

export class BackendDisplayableError extends Error {}
export class BackendDisplayableErrorList extends Error {
  public errors: string[];

  constructor(errors: string[], message?: string) {
    super(message || 'There are errors in the backend response');
    this.name = 'BackendDisplayableErrorList';
    this.errors = errors;

    // Maintaining proper stack trace for where our error was thrown (only available on V8 engines)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BackendDisplayableErrorList);
    }

    // Set the prototype explicitly (for transpilation to ES5)
    Object.setPrototypeOf(this, BackendDisplayableErrorList.prototype);
  }
}

export const throwIfNoRecordsDeleted = async (d: AxiosResponse<DeletedRecord>): Promise<AxiosResponse<any>> => {
  if (d?.data?.affected === 0) {
    throw new BackendDisplayableError(TranslateWithoutHook('GENERAL.ACTIONS.NO_RECORDS_DELETED'));
  }

  return d;
}