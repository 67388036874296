import clsx from 'clsx';
import {ChangeEventHandler, MouseEventHandler} from 'react';

export type CustomCheckboxProps = {
  label: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
  value: any;
  asSwitch?: boolean;
  isLTR?: boolean;
  isRadio?: boolean;
  name?: string;
  bold?: boolean;
  className?: React.HTMLAttributes<any>['className'];
};

export const CustomCheckbox = ({
  label,
  value,
  checked,
  onChange,
  asSwitch,
  isLTR,
  isRadio,
  onClick,
  name,
  bold,
  className,
}: CustomCheckboxProps) => {
  return (
    <div
      className={clsx('form-check form-check-custom ' + className ? className : '', {
        'form-check-solid': !asSwitch,
        'form-switch': asSwitch,
      })}
    >
      <label
        style={{
          color: '#5E6278',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          fontWeight: bold ? '800' : '500',
        }}
      >
        {isLTR && label}
        <input
          className='form-check-input'
          checked={checked}
          type={isRadio ? 'radio' : 'checkbox'}
          onChange={onChange}
          onClick={onClick}
          value={value}
          name={name}
        />
        {!isLTR && label}
      </label>
    </div>
  );
};
