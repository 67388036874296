import { DEFAULT_NAMESPACE } from "../constants/default-namespace"
import { allMessages } from '../../../_metronic/i18n/i18nProvider';
import { createIntl, createIntlCache } from 'react-intl';
import { I18N_CONFIG_KEY, initialState } from "../../../_metronic/i18n/Metronici18n";

export default function TranslateWithoutHook(key: string = '', nameSpace: string = DEFAULT_NAMESPACE) {
    const I18N_LANG = localStorage.getItem(I18N_CONFIG_KEY);
    const locale = (I18N_LANG) ? JSON.parse(I18N_LANG)?.selectedLang : initialState.selectedLang;
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: locale }));
    const messages = allMessages[locale];
    const cache = createIntlCache();
    const intl = createIntl({
        locale,
        messages: {
            [key]: messages[key]
        },
    }, cache);
    return intl.formatMessage({ id: key })
}