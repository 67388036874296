import {FormikHelpers, FormikState, FormikValues, useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';

export type useFormikWrapperProps<T extends FormikValues = any> = {
  initialValues: T;
  validationSchema?: Yup.ObjectSchema<any>;
  submitFormRef?: React.MutableRefObject<((() => Promise<void>) & (() => Promise<any>)) | null>;
  resetFormRef?: React.MutableRefObject<
    | (((nextState?: Partial<FormikState<T>> | undefined) => void) &
        ((nextState?: Partial<FormikState<T>> | undefined) => void))
    | null
  >;
  onError?: (boolean, errors?: Record<string, any>) => void;
  onSubmit: (data: T, formikHelpers?: FormikHelpers<T>) => Promise<boolean> | Promise<any>;
};

// (nextState?: Partial<FormikState<T>> | undefined) => void

export const useFormikWrapper = <T extends FormikValues>({
  initialValues,
  submitFormRef,
  resetFormRef,
  onError,
  validationSchema,
  onSubmit,
}: useFormikWrapperProps<T>) => {
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const formik = useFormik<T>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: triedToSubmit,
    validateOnChange: triedToSubmit,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (submitFormRef) {
      submitFormRef.current = formik.submitForm;
    }
  }, [submitFormRef, formik.submitForm]);

  useEffect(() => {
    if (resetFormRef) {
      resetFormRef.current = formik.resetForm;
    }
  }, [resetFormRef, formik.resetForm]);

  useEffect(() => {
    const hasErrors = Object.keys(formik.errors).length > 0;

    if (!triedToSubmit && hasErrors) {
      setTriedToSubmit(true);
    }

    if (initialValues !== formik.values || triedToSubmit) {
      onError?.(hasErrors, formik.errors);
    }
  }, [formik.values, formik.errors, triedToSubmit, initialValues, onError]);

  return formik;
};
