import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {I18nProvider} from '../_metronic/i18n/i18nProvider';
import {MasterInit} from '../_metronic/layout/MasterInit';
import {LayoutProvider, LayoutSplashScreen, PageDataProvider} from '../_metronic/layout/core';
import {ThemeModeProvider} from '../_metronic/partials';
import CustomModal from '../components/CustomModal';
import {AuthInit} from './modules/auth';

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <PageDataProvider>
            <ThemeModeProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
              <ToastContainer position='bottom-right' />
            </ThemeModeProvider>
          </PageDataProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export {App};
