/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../helpers';
import { useLayout } from '../../core';
import { HeaderToolbar } from './HeaderToolbar';
import { ReactComponent as Logo } from '../../../../assets/logo-white-bg.svg';


export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout();
  const { aside } = config;

  return (
    <div
      id='kt_header'
      className={clsx('header no-print', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div className='header-brand no-print'>
        {/* begin::Logo */}
        <Link to='/'>
          <Logo width={150} />
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-3 btn-active-color-primary aside-minimize border btn-icon-gray-500 border-gray-300 border-active-primary'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            {/* <KTIcon iconName='black-left-line' className='fs-1 me-n1 minimize-default' />
            <KTIcon iconName='black-right-line' className='fs-1 minimize-active' /> */}
            <KTIcon iconName='right-left' className='fs-2' />
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex no-print align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTIcon iconName='abstract-14' className='fs-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      {/* end::Brand */}
      <HeaderToolbar />
    </div>
  );
}
