import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {MenuComponent} from '../assets/ts/components';
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials';
import {Content} from './components/Content';
import {ScrollTop} from './components/ScrollTop';
import {AsideDefault} from './components/aside/AsideDefault';
import {HeaderWrapper} from './components/header/HeaderWrapper';
import {PageDataProvider} from './core';
import {Footer} from './components/Footer';

const MasterLayout = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      <ScrollTop />
    </>
  );
};

export {MasterLayout};
