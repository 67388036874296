import clsx from 'clsx';
import { Form, FormikProvider } from 'formik';
import { createContext, useContext, useState } from 'react';
import { AppButton } from '../../../components/appButton/AppButton';
import { useCustomFormik } from '../../../components/formik-form/useCustomFormik';
import { useDefaultRequestHandler } from '../../../utils/hooks/useRequestHandler';
import { LoanSettingType } from '../../pages/loans/loans.models';
import { saveLoanSettings } from '../../pages/loans/loans.services';

const useExchangeRateForm = ({onSuccess, exchangeRate, settingsId}: UseExchangeRateFormProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const {runPromise} = useDefaultRequestHandler({
    onSuccessMessage: 'Tasa de cambio guardada',
    navigateOnSuccess: false,
  });

  const onSubmit = async (s: LoanSettingType) => {
    setIsSaving(true);
    await runPromise(saveLoanSettings({...s, id: settingsId}));
    setIsSaving(false);
    onSuccess(s.exchangeRate);
  };

  const formik = useCustomFormik<LoanSettingType>({
    initialValues: {
      exchangeRate,
    },
    onSubmit,
  });
  return {
    isSaving,
    ...formik,
  };
};

const ExchangeRateFormContext = createContext<ReturnType<typeof useExchangeRateForm>>(null as any);

type UseExchangeRateFormProps = {
  onSuccess: Function;
  settingsId?: string;
  exchangeRate?: number;
};

const ExchangeRateFormWrapper = (props: UseExchangeRateFormProps) => {
  const exchangeRateForm = useExchangeRateForm(props);
  return (
    <ExchangeRateFormContext.Provider value={exchangeRateForm}>
      <FormikProvider value={exchangeRateForm.formikContext}>
        <ExchangeRateForm />
      </FormikProvider>
    </ExchangeRateFormContext.Provider>
  );
};

const ExchangeRateForm = () => {
  const {FormikInput, isSaving} = useContext(ExchangeRateFormContext);
  return (
    <Form>
      <div className='d-flex gap-3'>
        <FormikInput name='exchangeRate' className='w-125px' isNumberFormatter type='number' />
        <AppButton
          disabled={isSaving}
          text={'Guardar'}
          className={clsx('btn-primary')}
          type={'submit'}
        />
      </div>
    </Form>
  );
};

export default ExchangeRateFormWrapper;
