import axios, {AxiosResponse} from 'axios';
import {DeletedRecord, GenericCatalogType, LoanDelayRecord} from '../../../models';
import {
  CoDebtor,
  InstallmentRow,
  LoanSettingType,
  LoanType,
  NextPayment,
  OtherExpense,
} from './loans.models';
import {format} from 'date-fns';
import {DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_WITH_TIME} from '../../../constants';

export const URL_LOANS = `/loan/`;

export const getLoanById = async (id) => {
  return axios.get(`${URL_LOANS}${id}`).then((d: AxiosResponse<LoanType>) => d.data);
};

export const saveLoan = async (loan: LoanType, settings: LoanSettingType) => {
  return axios({
    url: `${URL_LOANS}${loan.id || ''}`,
    method: loan.id ? 'patch' : 'post',
    data: {
      loanDate: format(loan.loanDate as any, DEFAULT_DATE_FORMAT_WITH_TIME),
      surchargePercentage: settings.surchargePercentage,
      amortizationTypeId: settings.amortizationType?.id,
      currencyId: settings.currency?.id,
      cashId: settings.cash?.id,
      contractTemplateId: settings.contractTemplateId,
      receiptTemplateId: settings.receiptTemplateId,
      noticeTemplateId: settings.noticeTemplateId,
      excludeWeekDays: loan?.excludeWeekDaysArr?.length
        ? loan?.excludeWeekDaysArr?.join(',')
        : undefined,
      excludeWeekDaysArr: undefined,
      id: undefined,
      paymentIntervalId: loan.paymentIntervalId,
      clientId: loan.clientId,
      termTypeId: loan.termTypeId,
      loanTerm: loan.loanTerm,
      amountBorrowed: loan.amountBorrowed,
      interestPercentage: loan.interestPercentage,
      surchargePerDay: settings.surchargeByDay,
      walletId: loan.walletId,
      exchangeRate: settings.exchangeRate,
    },
  }).then((d: AxiosResponse<LoanType>) => d.data);
};

export const URL_TERM_TYPE = `${URL_LOANS}catalogs/termType/`;
export const getTermTypes = async () => {
  return axios.get(URL_TERM_TYPE).then((d: AxiosResponse<GenericCatalogType[]>) => d.data);
};

export const URL_LOAN_SETTINGS = `${URL_LOANS}settings/`;
export const getLoanSettings = async () => {
  return axios.get(URL_LOAN_SETTINGS).then((d: AxiosResponse<LoanSettingType[]>) => d.data[0]);
};

export const saveLoanSettings = async (settings: LoanSettingType) => {
  const {
    amortizationTypeId,
    cashId,
    currencyId,
    surchargeByDay,
    surchargePercentage,
    noticeTemplateId,
    receiptTemplateId,
    contractTemplateId,
    exchangeRate,
  } = settings;

  return axios({
    url: `${URL_LOAN_SETTINGS}${settings.id || ''}`,
    method: settings.id ? 'patch' : 'post',
    data: {
      amortizationTypeId,
      cashId,
      currencyId,
      surchargeByDay,
      surchargePercentage,
      noticeTemplateId,
      receiptTemplateId,
      contractTemplateId,
      exchangeRate,
    },
  }).then((d: AxiosResponse<LoanSettingType>) => d.data);
};

const URL_LOAN_INSTALLMENTS = `${URL_LOANS}installments`;

export const getInstallmentsByParams = (data: {
  amountBorrowed: number;
  paymentInterval: number;
  termType: number;
  interestPercentage: number;
  loanTerm: number;
  loanDate: Date;
  excludeWeekDays?: string;
}) => {
  return axios
    .post(URL_LOAN_INSTALLMENTS, {
      ...data,
      loanDate:
        data.loanDate instanceof Date ? format(data.loanDate, DEFAULT_DATE_FORMAT) : data.loanDate,
    })
    .then((d: AxiosResponse<InstallmentRow[]>) =>
      d.data.sort((a, b) => a.installmentNumber - b.installmentNumber)
    );
};

const URL_EXPENSES_TYPES = `${URL_LOANS}catalogs/expenseType`;
export const getExpensesTypes = async () => {
  return axios.get(URL_EXPENSES_TYPES).then((d: AxiosResponse<GenericCatalogType[]>) => d.data);
};

export const getExpensesByLoanId = async (loanId) => {
  return axios
    .get(`${URL_LOANS}${loanId}/expenses`)
    .then((d: AxiosResponse<OtherExpense[]>) => d.data);
};

const URL_EXPENSES = `${URL_LOANS}expenses`;
export const createLoanExpense = (expense: OtherExpense) => {
  return axios.post(URL_EXPENSES, {...expense, amount: Number(expense.amount)}).then((d: AxiosResponse<OtherExpense>) => d.data);
};

export const deleteLoanExpenseById = (expenseId: string) => {
  return axios
    .delete(`${URL_EXPENSES}/${expenseId}`)
    .then((d: AxiosResponse<DeletedRecord>) => d.data);
};

const URL_CODEBTORS = `${URL_LOANS}codebtors`;
export const createLoanCoDebtor = (coDebtor: CoDebtor) => {
  return axios.post(URL_CODEBTORS, coDebtor).then((d: AxiosResponse<CoDebtor>) => d.data);
};

export const getLoanCoDebtorsByLoanId = async (loanId) => {
  return axios
    .get(`${URL_LOANS}${loanId}/codebtors`)
    .then((d: AxiosResponse<CoDebtor[]>) => d.data);
};

export const deleteLoanCoDebtorById = (coDebtorId: string) => {
  return axios
    .delete(`${URL_CODEBTORS}/${coDebtorId}`)
    .then((d: AxiosResponse<DeletedRecord>) => d.data);
};

export const getNextPayment = async (params: [loanId: string, paymentDate: string]) => {
  const [loanId, paymentDate] = params;
  return axios
    .get(`${URL_LOANS}${loanId}/next-payment?paymentDate=${paymentDate}`)
    .then((d: AxiosResponse<NextPayment>) => d.data);
};

export const updateLoanDelay = async (date: Date) => {
  return axios
    .put(`${URL_LOANS}overdue`, {
      currentDate: format(date, DEFAULT_DATE_FORMAT),
    })
    .then((d: AxiosResponse<LoanDelayRecord[]>) => d.data);
};

export const getDocumentTemplates = async (id, type) => {
  return axios.get(`${URL_LOANS}${id}/template/${type}`).then((d: AxiosResponse<string>) => d.data);
};
