import React, {useMemo} from 'react';
import clsx from 'clsx';
import {useLocation} from 'react-router';
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers';
import {IconNames} from '../../../helpers/icons-config/icons';
import { useAsideMenu } from './AsideMenu';

type Props = {
  to?: string;
  title: string;
  icon?: IconNames;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation();
  const {activeItem} = useAsideMenu();
  const isActive = useMemo(() => {
    let res = false;
    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        const activeAtIndex = checkIsActive(pathname, child.props.to);
        if (activeItem?.path === child.props.to && activeItem?.atIndex === activeAtIndex) {
          res = true;
          return;
        }
      }
    });
    return res;
  }, [activeItem?.atIndex, activeItem?.path, children, pathname]);

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon as IconNames} className='fs-2' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  );
};

export {AsideMenuItemWithSub};
