import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const CustomTooltip = ({placement = 'top', id, tooltipContent, children}) => {
  return (
    <OverlayTrigger
      placement={placement as any}
      overlay={<Tooltip id={id}>{tooltipContent}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
