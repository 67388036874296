import axios, {AxiosResponse} from 'axios';
import {auth0Axios} from '../auth';
import {AssignedPermissionListType, Permission, permissionLabels} from './users.models';
import {Role, UserListType, UserType} from '../../pages/users/users.models';
import {PermissionNameMap} from './users.constants';
import {DeletedRecord} from '../../../models';
import {throwIfNoRecordsDeleted} from '../../../utils/axios';

export const URL_USERS = `/users/`;

export const getCurrentUser = async () => {
  return axios.get(`${URL_USERS}current`).then((d: AxiosResponse<UserType>) => d.data);
};

export const getUserList = async (query: Record<string, any>) => {
  return axios.get(URL_USERS, {params: query}).then((d: AxiosResponse<UserListType>) => d.data);
};

export const getUserById = async (id) => {
  return axios.get(`${URL_USERS}${id}`).then((d: AxiosResponse<UserType>) => d.data);
};

export const saveUser = async (user: UserType) => {
  const {cashIdsArr = [], segmentIdsArr = [], ...userData} = user;
  return axios({
    url: `${URL_USERS}${user.id || ''}`,
    method: user.id ? 'patch' : 'post',
    data: {
      ...userData,
      cashIds: cashIdsArr.join(','),
      segmentIds: segmentIdsArr.join(','),
      id: undefined,
    },
  }).then((d: AxiosResponse<UserType>) => d.data);
};

export const getUsers = async () => {
  try {
    const response = await auth0Axios.get('users');
    return response.data;
  } catch (error) {
    console.error('Error fetchingusers:', error);
  }
};

// Retrieve Permissions
export const getPermissions = async () => {
  try {
    const response = (await auth0Axios.get(
      'resource-servers'
    )) as AxiosResponse<AssignedPermissionListType>;
    return response.data;
  } catch (error) {
    console.error('Error fetching permissions:', error);
  }
};

export const getUserPermissions = async (userId) => {
  try {
    const response = await auth0Axios.get(`users/${userId}/permissions`);
    return response.data;
  } catch (error) {
    console.error('Error fetching permissions:', error);
  }
};

// Assign Permissions to User
export const assignPermissionsToUser = async (userId, permissions) => {
  try {
    const response = await auth0Axios.post(`users/${userId}/permissions`, {permissions});
    return response.data;
  } catch (error) {
    console.error('Error assigning permissions:', error);
  }
};

export const getRoleList = async () => {
  try {
    return await auth0Axios.get('roles').then((d: AxiosResponse<Role[]>) => d.data);
  } catch (error) {
    console.error('Error retrieving roles:', error);
  }
};

export const getRoleByUser = async (authId?: string) => {
  if (!authId) {
    return;
  }

  try {
    return await auth0Axios
      .get(`users/${authId}/roles`)
      .then((d: AxiosResponse<Role[]>) => d.data.map((r) => r.id));
  } catch (error) {
    console.error('Error retrieving users role:', error);
  }
};

export const saveUserRoles = async (authId: string, currentRoles: string[], newRoles: string[]) => {
  if (!authId) {
    return;
  }

  const url = `users/${authId}/roles`;

  try {
    if (currentRoles.length) {
      await auth0Axios({
        method: 'delete',
        url,
        data: {
          roles: currentRoles,
        },
      }).then((d) => true);
    }

    return await auth0Axios({
      method: 'post',
      url,
      data: {
        roles: newRoles,
      },
    }).then((d) => true);
  } catch (error) {
    console.error('Error retrieving users role:', error);
  }
};

export const deleteUser = async (userId: string) => {
  try {
    return await axios
      .delete(`${URL_USERS}${userId}`)
      .then(throwIfNoRecordsDeleted)
      .then((d: AxiosResponse<DeletedRecord>) => d.data);
  } catch (error) {
    console.error('Error deleting user:', error);
  }
};

export const getPermissionsByRole = async (roleId) => {
  try {
    return await auth0Axios
      .get(`roles/${roleId}/permissions`)
      .then((d: AxiosResponse<Permission[]>) => d.data);
  } catch (error) {
    console.error('Error retrieving permissions by role:', error);
  }
};

export const getPermissionsByRoleList = async (roleList: Role[]) => {
  try {
    const promises = roleList.map((r) =>
      getPermissionsByRole(r.id).then((permissions = []) => ({
        roleId: r.id,
        roleName: r.name,
        permissionMap: permissions.reduce((res, permission) => {
          const [, _name] = permission.permission_name.split(':');
          const name = permissionLabels[_name] || _name;
          const permissionName = PermissionNameMap[name] || name; // Fallback to its original name

          if (!res[permissionName]) {
            res[permissionName] = [];
          }

          res[permissionName].push(permission);

          return res;
        }, {} as Record<string, Permission[]>),
      }))
    );
    return await Promise.all(promises);
  } catch (error) {
    console.error('Error retrieving permissions by role:', error);
  }
};
