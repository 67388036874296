import {useField} from 'formik';
import {CustomTextArea, CustomTextAreaProps} from '../../form/CustomTextArea';
import {FormikFieldProps} from '../FormikField';
import {useInputActions} from '../useInputActions';

export type FormikTextAreaProps = CustomTextAreaProps & FormikFieldProps;

export const FormikTextArea = ({dependsOn, onDependencyChange, ...props}: FormikTextAreaProps) => {
  const {actionManagedProps} = useInputActions({dependsOn, onDependencyChange, name: props.name});
  const [field, {error}] = useField(props);

  return (
    <div className='w-100 position-relative'>
      <CustomTextArea {...field} {...props} {...actionManagedProps} error={error} />
    </div>
  );
};
