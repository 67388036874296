import {createRoot} from 'react-dom/client';
// Axios
import axios from 'axios';
import {Chart, registerables} from 'chart.js';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
// Apps
//import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n';
import './_metronic/assets/fonticon/fonticon.css';
import './_metronic/assets/keenicons/duotone/style.css';
import './_metronic/assets/keenicons/outline/style.css';
import './_metronic/assets/keenicons/solid/style.css';
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';
import 'react-toastify/dist/ReactToastify.css';
import {AppRoutes} from './app/routing/AppRoutes';
import {setupAxiosBearerToken} from './app/modules/auth';
// import devSetup from './dev/devSetup';
import {setupAxios} from './setupAxios';
import {Auth0Provider} from '@auth0/auth0-react';
import history from './utils/history.';
import {getConfig} from './auth0.config';
import {pdfjs} from 'react-pdf';
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n';
import { CurrentUserProviderProvider } from './app/modules/auth/core/CurrentUser';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxiosBearerToken(axios);
Chart.register(...registerables);

setupAxios();
// devSetup({axios});

const onRedirectCallback = (appState) => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? {audience: config.audience} : null),
  },
};

const queryClient = new QueryClient();
const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <CurrentUserProviderProvider>
          <Auth0Provider {...(providerConfig as any)}>
            <AppRoutes />
          </Auth0Provider>
        </CurrentUserProviderProvider>
      </MetronicI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
  );
}
