import {useField, useFormikContext} from 'formik';
import CustomPopoverColorPicker, {
  CustomPopoverColorPickerProps,
} from '../../form/CustomPopoverColorPicker';
import {FormikFieldProps} from '../FormikField';
import {useInputActions} from '../useInputActions';

export type FormikPopoverColorPickerProps = Omit<
  CustomPopoverColorPickerProps,
  'color' | 'onChange'
> &
  FormikFieldProps;

export const FormikPopoverColorPicker = ({
  dependsOn,
  onDependencyChange,
  ...props
}: FormikPopoverColorPickerProps) => {
  const {actionManagedProps} = useInputActions({dependsOn, onDependencyChange, name: props.name});
  const [field, {error}] = useField(props as any);
  const formContext = useFormikContext();

  return (
    <CustomPopoverColorPicker
      {...field}
      {...props}
      {...actionManagedProps}
      error={error}
      color={field.value}
      onChange={(color: string) => {
        formContext.setFieldValue(props.name, color);
      }}
    />
  );
};
