import {FC, useEffect} from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers';
import {IconNames} from '../../../helpers/icons-config/icons';
import {useAsideMenu} from './AsideMenu';

type Props = {
  to: string;
  title: string;
  icon?: IconNames;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation();
  const isActiveAtIndex = checkIsActive(pathname, to);
  const {activeItem, setActiveItem} = useAsideMenu();
  const highlight = activeItem?.path === to && activeItem?.atIndex === isActiveAtIndex;
  useEffect(() => {
    if (isActiveAtIndex !== false && (!activeItem || (isActiveAtIndex <= activeItem?.atIndex && activeItem?.path !== to))) {
      setActiveItem({path: to, atIndex: isActiveAtIndex});
    }
  }, [activeItem, isActiveAtIndex, setActiveItem, to]);

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: highlight})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon as IconNames} className='fs-2' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  );
};

export {AsideMenuItem};
