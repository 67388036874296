import {usePermission} from '../../../../app/modules/auth/core/Permissions';
import useCustomTranslation from '../../../../modules/translation/hook/use-custom-translation';
import {AsideMenuItem} from './AsideMenuItem';
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub';

export function AsideMenuMain() {
  const {getTranslation} = useCustomTranslation();
  const {hasPermission, hasAnyPermission, loading, error} = usePermission();

  if (loading || error) {
    return null;
  }

  return (
    <>
      {hasPermission('read:stadistic-section') && (
        <AsideMenuItem
          title={'Estadísticas'}
          to='/dashboard'
          // hasBullet={true}
          icon='chart-line'
        />
      )}
      {hasAnyPermission([
        'read:payment-section',
        'read:loan-section',
        'read:segment-section',
        'read:cash-section',
        'read:wallet-section',
        'read:expense-section',
        'read:note-credit-debit-section',
        'read:client-section',
      ]) && (
        <AsideMenuItemWithSub
          to='/'
          title={'Gestión Financiera'}
          // title={getTranslation('GENERAL.MENU.MODULES')}
          icon='dollar'
        >
          {hasPermission('read:payment-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.PAYMENT')}
              to='/pagos'
              hasBullet={true}
            />
          )}
          {hasPermission('read:loan-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.LOANS')}
              to='/prestamos'
              hasBullet={true}
            />
          )}
          {hasPermission('read:segment-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.SEGMENTS')}
              to='/rutas'
              hasBullet={true}
            />
          )}
          {hasPermission('read:cash-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.CASH')}
              to='/cajas'
              hasBullet={true}
            />
          )}
          {hasPermission('read:wallet-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.WALLETS')}
              to='/carteras'
              hasBullet={true}
            />
          )}
          {hasPermission('read:expense-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.EXPENSES')}
              to='/gastos'
              hasBullet={true}
            />
          )}
          {hasPermission('read:note-credit-debit-section') && (
            <AsideMenuItem
              // title={getTranslation('GENERAL.MENU.TEMPLATES')}
              title='Notas C/D'
              to='/notas'
              hasBullet={true}
            />
          )}
          {hasPermission('read:client-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.CLIENTS')}
              to='/clientes'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      )}
      {hasAnyPermission([
        'read:report-statement-section',
        'read:report-loan-section',
        'read:report-payment-section',
        'read:report-cash-section',
        'read:report-wallet-section',
        'read:report-segment-section',
        'read:report-client-section',
      ]) && (
        <AsideMenuItemWithSub title={'Informes'} icon='document'>
          {/* <AsideMenuItemWithSub to='/' title={getTranslation('GENERAL.MENU.MODULES')} icon='element-10'> */}
          {hasPermission('read:report-statement-section') && (
            <AsideMenuItem
              // title={getTranslation('GENERAL.MENU.TEMPLATES')}
              title='Estado de cuenta'
              to='/informe-estado-cuenta'
              hasBullet={true}
            />
          )}
          {hasPermission('read:report-loan-section') && (
            <AsideMenuItem
              // title={getTranslation('GENERAL.MENU.TEMPLATES')}
              title='Préstamos'
              to='/informe-prestamos'
              hasBullet={true}
            />
          )}
          {hasPermission('read:report-payment-section') && (
            <AsideMenuItem
              // title={getTranslation('GENERAL.MENU.TEMPLATES')}
              title='Pagos'
              to='/informe-pagos'
              hasBullet={true}
            />
          )}
          {hasPermission('read:report-cash-section') && (
            <AsideMenuItem
              // title={getTranslation('GENERAL.MENU.TEMPLATES')}
              title='Cajas'
              to='/informe-cajas'
              hasBullet={true}
            />
          )}
          {hasPermission('read:report-wallet-section') && (
            <AsideMenuItem
              // title={getTranslation('GENERAL.MENU.TEMPLATES')}
              title='Carteras'
              to='/informe-carteras'
              hasBullet={true}
            />
          )}
          {hasPermission('read:report-segment-section') && (
            <AsideMenuItem
              // title={getTranslation('GENERAL.MENU.TEMPLATES')}
              title='Rutas'
              to='/informe-rutas'
              hasBullet={true}
            />
          )}
          {hasPermission('read:report-client-section') && (
            <AsideMenuItem
              // title={getTranslation('GENERAL.MENU.TEMPLATES')}
              title='Clientes'
              to='/informe-clientes'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      )}
      {hasAnyPermission(['read:user-section', 'read:template-section']) && (
        <AsideMenuItemWithSub title={'Confguraciones'} icon='abstract-1'>
          {hasPermission('read:template-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.TEMPLATES')}
              to='/plantillas'
              hasBullet={true}
            />
          )}
          {hasPermission('read:user-section') && (
            <AsideMenuItem
              title={getTranslation('GENERAL.MENU.USERS')}
              to='/usuarios'
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      )}
    </>
  );
}
