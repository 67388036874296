/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useAuth0} from '@auth0/auth0-react';
import {FC} from 'react';
import styled from 'styled-components';
import useCustomTranslation from '../../../../modules/translation/hook/use-custom-translation';
import {KTIcon} from '../../../helpers';
import {useLayout} from '../../core';
import {AsideMenu} from './AsideMenu';

const LogoutButton = styled.button`
  background-color: transparent !important;
  display: flex;
  gap: 7.5px;
  border: none;
  align-items: center;
  color: white;
`;

const AsideDefault: FC = () => {
  const {classes} = useLayout();
  const {logout} = useAuth0();
  const {getTranslation} = useCustomTranslation();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <div
      id='kt_aside'
      className='aside no-print'
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto py-5' id='kt_aside_footer'>
        <LogoutButton onClick={logoutWithRedirect}>
          <KTIcon iconName='exit-left' className='fs-1 me-n1 minimize-default text-white' />
          {getTranslation('GENERAL.LOGOUT')}
        </LogoutButton>
      </div>
      {/* end::Footer */}
    </div>
  );
};

export {AsideDefault};
