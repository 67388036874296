import React, {FC, createContext, useCallback, useContext, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {KTIcon, WithChildren} from '../_metronic/helpers';
import styled, {css} from 'styled-components';
import clsx from 'clsx';
import getTranslation from '../modules/translation/helpers/translate-without-hook';
import {EventEmitter} from 'events';
import {useNavigate, useLocation} from 'react-router-dom';
import {AppButton} from './appButton/AppButton';

const modalEvents = new EventEmitter();

export const modalEventHandlers = {
  emitModalEvent: (eventName: string, data?: any) => {
    modalEvents.emit(eventName, data);
  },
  listenForModalEvent: (eventName: string, callback: any) => {
    modalEvents.on(eventName, callback);
  },
  removeModalEventListener: (eventName: string, callback: any) => {
    modalEvents.off(eventName, callback);
  },
};

const initialButtonState = {
  isYesDisabled: false,
  loading: false,
};

const _useModal = () => {
  const [buttonState, setButtonState] = useState<typeof initialButtonState>(initialButtonState);
  const [modalData, setModalData] = useState({});

  const resetButtonState = useCallback(() => {
    setButtonState(initialButtonState);
  }, [setButtonState]);

  return {
    buttonState,
    setButtonState,
    resetButtonState,
    modalData,
    setModalData,
    ...modalEventHandlers,
  };
};

type ModalContextType = ReturnType<typeof _useModal>;

const ModalContext = createContext<ModalContextType>(null as any);

const ModalProvider: FC<WithChildren> = ({children}) => {
  const dt = _useModal();

  return <ModalContext.Provider value={dt}>{children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);

type ModalButtonProps = {
  text: string | [JSX.Element];
  onClick?: Function;
  className?: string;
  disabled?: boolean;
};

export const ModalButton = ({text, onClick, className, disabled}: ModalButtonProps) => (
  <button
    disabled={disabled}
    type='button'
    className={`btn btn-sm ${className}`}
    onClick={onClick as any}
  >
    {text}
  </button>
);

export const YesNoModal = ({
  yesText = getTranslation('GENERAL.OK'),
  waitText = getTranslation('GENERAL.PLACEHOLDER.PLEASE_WAIT'),
  noText = getTranslation('GENERAL.CANCEL'),
  onYesClick,
  onNoClick,
  loading,
  isYesDisabled,
}) => {
  const {buttonState} = useModal();
  const isLoading = buttonState.loading || loading;
  const isDisabled = buttonState.loading || buttonState.isYesDisabled || loading || isYesDisabled;

  return (
    <>
      <ModalButton disabled={loading} text={noText} className={'btn-light'} onClick={onNoClick} />
      <ModalButton
        disabled={isDisabled}
        text={
          isLoading
            ? [
                <span className='indicator-progress d-block' key='loader'>
                  {waitText}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>,
              ]
            : yesText
        }
        className={'btn-primary'}
        onClick={onYesClick}
      />
    </>
  );
};

export const CloseOnlyModal = ({text = getTranslation('GENERAL.CLOSE'), onClick}) => {
  return <ModalButton text={text} className={'btn-light-primary'} onClick={onClick} />;
};

export type FooterPresetProps = {
  type: 'yes-no' | 'close-only';
  yesText?: string;
  noText?: string;
  loadingText?: string;
  okAction: Function;
  noAction?: Function;
};

export type ModalProps = {
  show?: boolean;
  showTitle?: boolean;
  showCloseButton?: boolean;
  content?: JSX.Element;
  customFooter?: JSX.Element;
  footerPreset?: FooterPresetProps;
  title?: string | JSX.Element;
  customTitle?: JSX.Element;
  modalHeaderEnd?: JSX.Element;
  size?: 'sm' | 'lg' | 'xl' | 'md';
  onClose?: Function;
  classes?: {
    header: string;
    footer: string;
  };
  loading?: boolean;
  error?: boolean;
  customAnimation?: 'alertIn' | 'smoothScaleIn' | 'smoothBounceIn' | 'bounceIn';
};

const defaultModal = {
  show: false,
  showTitle: true,
  showCloseButton: true,
  content: undefined,
  title: undefined,
  size: 'sm' as any,
  onClose: undefined,
};

const api = (() => {
  let handler;
  return {
    setHandler: (h) => {
      handler = h;
    },
    setModalState: (state: ModalProps = defaultModal, cachePrevious = false) => {
      if (handler) {
        if (cachePrevious) {
          handler((prev) => ({...prev, ...state}));
        } else {
          handler({...defaultModal, ...state});
        }
      }
    },
    ...modalEventHandlers,
  };
})();

export const setModalState = api.setModalState;

const FooterPreset: FC<
  {
    footerPreset: FooterPresetProps;
  } & {
    onClose?: Function;
    loading?: boolean;
    error?: boolean;
  }
> = ({footerPreset, onClose, loading, error}) => {
  if (footerPreset.type === 'yes-no') {
    return (
      <YesNoModal
        onYesClick={footerPreset.okAction}
        onNoClick={footerPreset.noAction || onClose}
        loading={loading}
        isYesDisabled={error}
        waitText={footerPreset.loadingText}
        yesText={footerPreset.yesText}
        noText={footerPreset.noText}
      />
    );
  }

  if (footerPreset.type === 'close-only') {
    return <CloseOnlyModal onClick={footerPreset.noAction || onClose} />;
  }

  return <></>;
};

const MemoizedModalBody = React.memo(function ModalBodyComponent({
  content,
}: {
  content?: JSX.Element;
}) {
  return <Modal.Body className='py-3'>{content}</Modal.Body>;
});

const modalAnimations = css`
  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes smoothBounceIn {
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    25% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    50% {
      opacity: 1;
      transform: scale3d(0.95, 0.95, 0.95);
    }
    75% {
      transform: scale3d(1.02, 1.02, 1.02);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes smoothScaleIn {
    0% {
      opacity: 0;
      transform: scale3d(0.95, 0.95, 0.95);
    }
    100% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes alertIn {
    0% {
      opacity: 0;
      transform: scale3d(0.9, 0.9, 0.9);
    }
    100% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
`;

const StyledModal = styled(Modal)`
  @media print {
    background-color: white;
    .modal-dialog {
      position: initial;
      padding: 0;
      margin: 0;
    }
    .modal-content {
      width: 100% !important;
      box-shadow: none;
    }

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;
    }

    .modal-header,
    .modal-footer {
      display: none;
    }
  }

  .modal-header {
    padding-top: 15px;
    padding-bottom: 10px;
    h2 {
      margin: 0;
    }
  }

  .modal-footer {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ${modalAnimations};
  &.custom-animation {
    transform: translateX(100%);
    transition: transform 0.3s ease-out;

    &.show {
      transform: translateX(0);
    }

    &.fade {
      &.alertIn {
        animation: alertIn 0.3s ease-out;
      }
      &.smoothScaleIn {
        animation: smoothScaleIn 0.5s ease-out;
      }
      &.smoothBounceIn {
        animation: smoothBounceIn 0.6s ease-out;
      }
      &.bounceIn {
        animation: bounceIn 0.6s ease-out;
      }
    }
  }
`;

const CustomModal = () => {
  const [modalState, setModalState] = useState<ModalProps>(defaultModal);
  useEffect(() => {
    api.setHandler(setModalState);
  }, []);

  const {
    show,
    showTitle,
    showCloseButton,
    content,
    title,
    size = defaultModal.size,
    onClose,
    classes,
    footerPreset,
    customFooter,
    loading,
    error,
    customAnimation,
    modalHeaderEnd,
    customTitle,
  } = modalState;

  const _onClose = () => {
    if (onClose) {
      onClose(); // Custom action
    }
    setModalState({show: false, content: <></>}); // Hide the modal
  };

  return (
    <ModalProvider>
      <StyledModal
        show={show}
        onHide={loading ? undefined : _onClose}
        size={size as any}
        className={clsx({
          'custom-animation': !!customAnimation,
          [customAnimation || 'no-animation']: !!customAnimation,
        })}
      >
        {showTitle && (
          <Modal.Header closeButton={showCloseButton} className={classes?.header}>
            {!!title && <h2 className='fw-bolder d-flex gap-2 flex-grow-1'>{title}</h2>}
            {!!customTitle && <div className='flex-grow-1'>{customTitle}</div>}
            {modalHeaderEnd}
          </Modal.Header>
        )}
        <MemoizedModalBody content={content} />
        {customFooter || footerPreset ? (
          <Modal.Footer className={classes?.footer}>
            {customFooter ? (
              customFooter
            ) : (
              <FooterPreset
                footerPreset={footerPreset!}
                onClose={_onClose}
                loading={loading}
                error={error}
              />
            )}
          </Modal.Footer>
        ) : null}
      </StyledModal>
    </ModalProvider>
  );
};

type NavigableModalProps = {
  title: ModalProps['title'];
  customTitle?: ModalProps['customTitle'];
  size: ModalProps['size'];
  footerPreset?: FooterPresetProps;
  goBackTo?: string;
  content: JSX.Element;
  modalHeaderEnd?: ModalProps['modalHeaderEnd'];
  canGoBack?: boolean;
};

export const NavigableModal = ({
  title,
  size,
  footerPreset,
  goBackTo,
  content,
  modalHeaderEnd,
  customTitle,
  canGoBack,
}: NavigableModalProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const _customTitle =
    customTitle || canGoBack ? (
      <AppButton
        text={
          <>
            <KTIcon iconName='arrow-left' className='text-primary fs-2' />
            Volver
          </>
        }
        className={'text-primary fs-6 fw-medium p-0'}
        onClick={() => {
          navigate(-1);
        }}
      />
    ) : undefined;

  useEffect(() => {
    api.setModalState({
      show: true,
      content,
      title,
      size,
      footerPreset,
      modalHeaderEnd,
      customTitle: _customTitle,
      onClose: () => {
        if (goBackTo) {
          navigate({pathname: goBackTo, search: location.search});
        } else {
          navigate(-1);
        }
      },
    });
  }, [location]);
  return null;
};

export default CustomModal;
