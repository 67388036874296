export const colors = {
  gray100: '#F9F9F9',
  gray200: '#F1F1F2',
  gray300: '#E1E3EA',
  gray400: '#D8D8E5',
  gray500: '#A1A5B7',
  gray600: '#7E8299',
  gray700: '#5E6278',
  gray800: '#3F4254',
  gray900: '#181C32',
  primary: '#4488c4',
  primary100: '#1E90FF',
  primaryContrast: '#89c543',
};
