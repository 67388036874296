import {useField, useFormikContext} from 'formik';
import CustomDatePicker, {CustomDatePickerProps} from '../../form/CustomDatePicker';
import {FormikFieldProps} from '../FormikField';
import {useInputActions} from '../useInputActions';

export type FormikDatePickerProps = CustomDatePickerProps & FormikFieldProps;

export const FormikDatePicker = ({
  dependsOn,
  onDependencyChange,
  ...props
}: FormikDatePickerProps) => {
  const {actionManagedProps} = useInputActions({dependsOn, onDependencyChange, name: props.name});
  const [field, {error}] = useField(props as any);
  const formContext = useFormikContext();
  return (
    <div className='w-100 position-relative'>
      <CustomDatePicker
        {...field}
        {...props}
        {...actionManagedProps}
        id={field.name}
        onChange={(e: Date) => {
          formContext.setFieldValue(props.name, e);
        }}
        error={error}
      />
    </div>
  );
};
