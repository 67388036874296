import { createContext, useContext, useState } from 'react';
import { UserType } from '../../../pages/users/users.models';

export const CurrentUserContext = createContext<{
  currentUser: UserType | null;
  setCurrentUser: (user: UserType | null) => void;
}>(null as any);

export const CurrentUserProviderProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState<UserType | null>(null);
  return <CurrentUserContext.Provider value={{
    currentUser,
    setCurrentUser
  }}>{children}</CurrentUserContext.Provider>;
};

export const useCurrentUser = () => useContext(CurrentUserContext);
