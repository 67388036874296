import axios from 'axios';
import {BackendDisplayableError, BackendDisplayableErrorList} from './utils/axios';
import {ValidationErrorResponse} from './models';
import _ from 'lodash';

// Define the custom paramsSerializer using Lodash
const customParamsSerializer = (params) => {
  const pairs = _.flatMap(_.toPairs(params), ([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((item) => [key, item]);
    }
    return [[key, value]];
  });

  return pairs.map(([key, value]) => `${key}=${value}`).join('&');
};

const middlewares = {
  request: [],
  response: [],
};

export const setupAxios = () => {
  // TODO move to the .env variable
  // axios.defaults.baseURL = 'http://172.21.0.3:3001/';
  // axios.defaults.baseURL = 'https://ox-picked-possibly.ngrok-free.app';
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

  // Ngrok bypass
  axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';

  // Serializer for multi-param
  axios.interceptors.request.use((config) => {
    if (config.params && _.some(config.params, _.isArray)) {
      const serializedParams = customParamsSerializer(config.params);
      config.url += `?${serializedParams}`;
      delete config.params; // Remove the params property so Axios doesn't serialize it again
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const {
        response: {data},
      } = error;

      if (data.statusCode < 500) {
        if (data.error && data.message) {
          throw new BackendDisplayableError(data.message);
        }

        if (data.errors && Array.isArray(data.errors)) {
          const _data = data as ValidationErrorResponse;
          const errors = _data.errors.reduce((res, e) => {
            res.push(...Object.values(e.constraints));
            return res;
          }, [] as string[]);
          throw new BackendDisplayableErrorList(errors);
        }
      }

      return Promise.reject(data);
    }
  );
};

/**
 * @param type {("request"|"response")}
 */
export const addMiddleware = (type, fn) => {
  middlewares[type].push(fn);
};

/**
 * @param type {("request"|"response")}
 */
export const removeMiddleware = (type, fn) => {
  middlewares[type] = middlewares[type].filter((x) => x !== fn);
};

axios.interceptors.request.use(async (request) => {
  const result = await applyMiddlewares('request', request);
  return result;
});

axios.interceptors.response.use(async (response) => {
  const result = await applyMiddlewares('response', response);
  return result;
});

const applyMiddlewares = async (type, r) => {
  let result = r;

  for (const fn of middlewares[type]) {
    const res = await fn(result);
    result = res;
  }

  return result;
};

// Utils

export const retryRequest = async (originalRequest) => {
  return axios.request({...originalRequest, _retry: true});
};

export const throwOnAxiosError = (req, message) => {
  if (typeof req === 'object' && req.isAxiosError) {
    throw new Error(message);
  }
  return req;
};
