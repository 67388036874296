import React from 'react';
import {KTIcon} from '../../_metronic/helpers';
import CustomTooltip from '../CustomTooltip';

type CustomControlErrorProps = {
  name: string;
  error: string;
  tooltipStyle?: React.CSSProperties;
};

export const CustomControlError = ({name, error, tooltipStyle}: CustomControlErrorProps) => {
  return (
    <CustomTooltip id={`form-${name}`} tooltipContent={error}>
      <div
        className='position-absolute right-0 top-50 translate-middle-y z-index-1'
        style={tooltipStyle || {right: 5}}
      >
        <KTIcon iconName='information-2' className='text-danger' />
      </div>
    </CustomTooltip>
  );
};
