export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string): number|false {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  const parts = current.split('/').filter(Boolean);

  // find the index in the parts array that matches the url path, it may have or not "/" at the beginning
  const index  = parts.findIndex((part) => part === url.split('/').filter(Boolean)[0]);

  if (index > -1) {
    return index;
  }

  return false;
}
