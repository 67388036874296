import {useFormikContext} from 'formik';
import React from 'react';
import {useDropzone} from 'react-dropzone';
import {toast} from 'react-toastify';
import {FILE_EXTENSIONS, MAX_FILE_SIZE, MAX_FILENAME_LENGTH, MIME_TYPES} from '../../constants';
import styled from 'styled-components';
import TranslateWithoutHook from '../../modules/translation/helpers/translate-without-hook';
import Compressor from 'compressorjs';

type BuildAllowedFileTypesProps = {
  images?: 'all' | string[];
  pdf?: boolean;
  rest?: Record<string, any>;
};

const isImage = (file: File): boolean => {
  const mimeType: string = file.type;
  const imageExtensions: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff'];
  const fileExtension: string = file.name.split('.').pop()?.toLowerCase() || '';

  return mimeType.startsWith('image/') || imageExtensions.includes(fileExtension);
};

export const buildAllowedFileTypes = ({images, pdf, rest}: BuildAllowedFileTypesProps) => {
  const accept = {};

  if (images) {
    if (images === 'all') {
      // Only accept JPEG and PNG file types
      accept[MIME_TYPES.jpeg] = [FILE_EXTENSIONS.jpeg];
      accept[MIME_TYPES.png] = [FILE_EXTENSIONS.png];
    } else {
      images.forEach((image) => {
        if (FILE_EXTENSIONS[image]) {
          accept[MIME_TYPES[image]] = [FILE_EXTENSIONS[image]];
        }
      });
    }
  }

  if (pdf) {
    accept[MIME_TYPES.pdf] = [FILE_EXTENSIONS.pdf];
  }

  if (rest) {
    Object.assign(accept, rest);
  }

  return accept;
};

const FormikFileInputContainer = styled.div`
  [role='presentation'] {
    outline: none;
  }
`;

export type FormikFileHandlerProps = {
  fileList: Blob[] | (Blob & {id?: string; mimeType: Blob['type']; url: string}[]);
  onDelete: (fileToDelete: any) => void;
  isDragActive: boolean;
  onFileClick?: (file: any) => void;
  viewMode?: boolean;
};

export type FormikFileInputProps = {
  FileHandlerComponent: ({fileList, onDelete, isDragActive}: FormikFileHandlerProps) => JSX.Element;
  name: string;
  accept: Record<string, any>;
  maxSize?: number;
  errorToastMessage?: string;
  multiple?: boolean;
  onFileClick?: (file: any) => void;
  disabled?: boolean;
};

export const FormikFileInput = ({
  FileHandlerComponent,
  name,
  accept,
  multiple,
  maxSize = MAX_FILE_SIZE,
  errorToastMessage = TranslateWithoutHook(
    'GENERAL.PLACEHOLDER.SOME_OF_THE_PROVIDED_FILES_ARE_INVALID'
  ),
  onFileClick,
  disabled,
}: FormikFileInputProps) => {
  const {values, setFieldValue} = useFormikContext<Record<string, any>>();

  const onDrop = React.useCallback(
    async (acceptedFiles, fileRejections) => {
      const invalidFiles = [] as any[];
      const validFiles = [] as any[];

      const processFiles = async () => {
        for (const file of acceptedFiles) {
          if (file.name.length > MAX_FILENAME_LENGTH) {
            invalidFiles.push(file);
          } else {
            if (isImage(file)) {
              try {
                const compressedFile = await new Promise((resolve, reject) => {
                  new Compressor(file, {
                    quality: 0.3,
                    success(result) {
                      resolve(result);
                    },
                    error(err) {
                      reject(err);
                    },
                  });
                });
                validFiles.push(compressedFile);
              } catch (error) {
                console.error(error);
              }
            } else {
              validFiles.push(file);
            }
          }
        }
      };

      await processFiles();

      if (multiple) {
        // Filter out files that are already in the values.files array
        const newFiles = validFiles.filter(
          (file) =>
            !values[name].some(
              (existingFile) => existingFile.name === file.name && existingFile.size === file.size
            )
        );
        // Append new, non-duplicate files to the existing files
        setFieldValue(name, [...values[name], ...newFiles]);
      } else {
        setFieldValue(name, validFiles);
      }

      if (fileRejections.length > 0 || invalidFiles.length > 0) {
        toast.error(errorToastMessage);
      }
    },
    [errorToastMessage, multiple, name, setFieldValue, values]
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple,
  });

  const onDelete = (fileToDelete) => {
    setFieldValue(
      name,
      values[name].filter((existingFile) => existingFile !== fileToDelete)
    );
  };

  return disabled ? (
    <FileHandlerComponent
      viewMode={disabled}
      fileList={values[name]}
      onDelete={onDelete}
      isDragActive={isDragActive}
      onFileClick={onFileClick}
    />
  ) : (
    <FormikFileInputContainer>
      <div {...getRootProps({className: ''})}>
        <input {...getInputProps()} name={name} />
        <FileHandlerComponent
          viewMode={disabled}
          fileList={values[name]}
          onDelete={onDelete}
          isDragActive={isDragActive}
          onFileClick={onFileClick}
        />
      </div>
    </FormikFileInputContainer>
  );
};
