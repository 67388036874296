import {FormikValues, useFormikContext} from 'formik';
import {getTypedFormikComponents} from './FormikField';
import {useFormikWrapper, useFormikWrapperProps} from './useFormikWrapper';
import {useMemo} from 'react';

type FormComponentsType<T> = ReturnType<typeof getTypedFormikComponents<T>>;

export type CustomFormik<T> = FormComponentsType<T> & {
  formikContext: ReturnType<typeof useFormikContext<T>>;
};

export const useCustomFormik = <T extends FormikValues>(props: useFormikWrapperProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formComponents = useMemo(() => getTypedFormikComponents(props.initialValues as T), []);

  const formikContext = useFormikWrapper<T>(props);
  return {
    formikContext,
    ...formComponents,
  };
};
