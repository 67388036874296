import {useAuth0} from '@auth0/auth0-react';
import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {PermissionDictionaryType} from '../../users/users.models';
import {getCurrentUser, getUserPermissions} from '../../users/users.services';
import {
  createPermissionDictionary,
  getDefaultRoute,
  userHasPermission,
} from '../../users/users.utils';
import {fetchAndSetAuth0Token} from './AuthHelpers';
import {useCurrentUser} from './CurrentUser';

export const PermissionContext = createContext<ReturnType<typeof _usePermission>>(null as any);

const _usePermission = () => {
  const [permissions, setPermissions] = useState<PermissionDictionaryType>({});
  const [_loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {user} = useAuth0();
  const {currentUser, setCurrentUser} = useCurrentUser();

  const {
    isFetching,
    refetch,
    data: _currentUser,
    error: userQueryError,
  } = useQuery('getCurrentUser', getCurrentUser, {
    cacheTime: 0,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: !!user,
  });

  useEffect(() => {
    if (_currentUser) {
      setCurrentUser({
        ..._currentUser,
        cashIdsArr:
          _currentUser?.cashIds !== ''
            ? _currentUser.cashIds?.split(',').map((id) => Number(id)) || []
            : [],
        segmentIdsArr:
          _currentUser.segmentIds !== ''
            ? _currentUser.segmentIds?.split(',').map((id) => Number(id)) || []
            : [],
      });
    }
  }, [_currentUser]);

  const loading = isFetching || _loading;

  const hasPermission = useCallback(
    (p: keyof PermissionDictionaryType) => userHasPermission(p, permissions),
    [permissions]
  );

  const hasAnyPermission = useCallback(
    (permissions: (keyof PermissionDictionaryType)[]) => permissions.some((p) => hasPermission(p)),
    [hasPermission]
  );

  const loadPermissions = useCallback(async () => {
    try {
      setLoading(true);
      await fetchAndSetAuth0Token();
      const permissions = await getUserPermissions(user?.sub);
      setPermissions(createPermissionDictionary(permissions));
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [user?.sub]);

  const getInitialRoute = useCallback(() => getDefaultRoute(permissions), [permissions]);

  useEffect(() => {
    loadPermissions();
  }, [loadPermissions]);

  return {
    getInitialRoute,
    hasPermission,
    loadPermissions,
    permissions,
    loading,
    error,
    user,
    currentUser,
    hasAnyPermission,
  };
};

export const PermissionProvider = ({children}) => {
  const value = _usePermission();
  return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
};

export const usePermission = () => useContext(PermissionContext);
