import React from 'react';
import CustomSelect, {CustomSelectProps} from '../../form/CustomSelect';
import {FormikFieldProps} from '../FormikField';
import {useInputActions} from '../useInputActions';
import {useField, useFormikContext} from 'formik';

export type FormikSelectProps = CustomSelectProps & FormikFieldProps;

const FormikSelect = ({dependsOn, onDependencyChange, isClearable = true, ...props}: FormikSelectProps) => {
  const {actionManagedProps} = useInputActions({dependsOn, onDependencyChange, name: props.name});
  const [field, {error}] = useField(props as FormikFieldProps);
  const formContext = useFormikContext();

  return (
    <div className='w-100 position-relative'>
      <CustomSelect
        isClearable={isClearable}
        altStyle
        value={field.value || ''}
        {...props}
        {...actionManagedProps}
        error={error}
        onChange={(e: any) => {
          if (props.isMulti){
            formContext.setFieldValue(props.name, e.map((el: any) => el.value));
          }else{
            formContext.setFieldValue(props.name, e?.value || null);
          }
        }}
      />
    </div>
  );
};

export default FormikSelect;
