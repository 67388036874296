import {FC, useMemo} from 'react';
import Select, {Props as ReactSelectProps, StylesConfig, components} from 'react-select';
import {CustomControlError} from './CustomControlError';

const Input = ({...rest}: any) => <components.Input {...rest} autoComplete={'nope'} />;

// <Select {...rest} components={{ Input }} />

const IndicatorSeparator = (props: any) => {
  return <></>;
};

export const buildCustomSelectOptions = ({
  data,
  labelKey,
  valueKey,
}: {
  data: any[];
  labelKey: string;
  valueKey: any;
}) => {
  return data.map((o) => ({label: o[labelKey], value: o[valueKey]}));
};

// Override Styles
const customAltStyles = (props: CustomSelectProps) =>
  ({
    placeholder: (provided) => ({
      ...provided,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      border: 'none !important',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: 'var(--bs-gray-700)',
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      zIndex: '3',
    }),
    control: (baseStyles, state) => {
      return {
        ...baseStyles,
        fontSize: '1rem !important',
        borderRadius: '6px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: state.isFocused
          ? 'var(--bs-gray-200)'
          : state.isDisabled
          ? 'var(--bs-gray-300)'
          : 'var(--bs-gray-100)',
        border: props.error ? '1px solid var(--bs-danger) !important' : 'none !important',
        color: 'var(--bs-gray-700)',
      };
    },
  } as StylesConfig);

const customStyles = (props: CustomSelectProps) =>
  ({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: '6px',
      border: props.error ? '1px solid var(--bs-danger)' : '1px solid #E1E3EA',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 3,
    }),
  } as StylesConfig);

const getStyle = (props: CustomSelectProps, type: 'alt' | 'default') => {
  if (type === 'alt') {
    return customAltStyles(props);
  } else {
    return customStyles(props);
  }
};

export type CustomSelectProps = ReactSelectProps & {
  altStyle?: boolean;
  disabled?: boolean;
  name?: string;
  error?: string;
  buildOptionsWith?: {
    data: any[];
    labelKey: string;
    valueKey: any;
  };
};

const CustomSelect: FC<CustomSelectProps> = ({altStyle, ...props}) => {
  const options = useMemo(() => {
    if (props.options) {
      return props.options;
    }

    if (props.buildOptionsWith?.data) {
      return buildCustomSelectOptions(props.buildOptionsWith!);
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options, props.buildOptionsWith?.data]);

  let value: unknown = undefined;

  if (props.isMulti) {
    value =
      props.value &&
      (options || []).filter((el: any) => ((props.value as any[]) || []).includes(el.value));
  } else {
    value = props.value && (options || []).find((el: any) => el.value === props.value);
  }

  return (
    <>
      <Select
        components={props.error ? {IndicatorSeparator, Input} : {Input}}
        styles={getStyle(props, altStyle ? 'alt' : 'default')}
        {...props}
        isDisabled={props.isDisabled || props.disabled}
        options={options}
        value={value}
        noOptionsMessage={() => 'No hay resultados'}
        placeholder='Seleccione...'
      />
      {!!props.error && !!props.name && (
        <CustomControlError name={props.name} error={props.error} tooltipStyle={{right: 30}} />
      )}
    </>
  );
};

export default CustomSelect;
