export type PermissionDictionaryType = {
  'read:cash'?: boolean;
  'create:cash'?: boolean;
  'update:cash'?: boolean;
  'delete:cash'?: boolean;

  'upload:attachment-client'?: boolean;
  'download:attachment-client'?: boolean;
  'read:attachment-client'?: boolean;
  'delete:attachment-client'?: boolean;
  'create:client'?: boolean;
  'read:client'?: boolean;
  'update:client'?: boolean;
  'delete:client'?: boolean;
  'create:client-company'?: boolean;
  'read:client-company'?: boolean;
  'update:client-company'?: boolean;
  'delete:client-company'?: boolean;
  'create:client-reference'?: boolean;
  'read:client-reference'?: boolean;
  'update:client-reference'?: boolean;
  'delete:client-reference'?: boolean;

  'upload:attachment-expense'?: boolean;
  'download:attachment-expense'?: boolean;
  'read:attachment-expense'?: boolean;
  'delete:attachment-expense'?: boolean;
  'read:expense'?: boolean;
  'create:expense'?: boolean;
  'update:expense'?: boolean;
  'delete:expense'?: boolean;

  'read:codebtor'?: boolean;
  'create:codebtor'?: boolean;
  'update:codebtor'?: boolean;
  'delete:codebtor'?: boolean;

  'read:credit-debit-note'?: boolean;
  'create:credit-debit-note'?: boolean;
  'update:credit-debit-note'?: boolean;
  'delete:credit-debit-note'?: boolean;

  'read:loan-expense'?: boolean;
  'create:loan-expense'?: boolean;
  'update:loan-expense'?: boolean;
  'delete:loan-expense'?: boolean;

  'read:loan-setting'?: boolean;
  'create:loan-setting'?: boolean;
  'update:loan-setting'?: boolean;
  'delete:loan-setting'?: boolean;

  'read:loan'?: boolean;
  'create:loan'?: boolean;
  'update:loan'?: boolean;
  'delete:loan'?: boolean;
  'installment:loan'?: boolean;
  'desert:loan'?: boolean;

  'read:general-chart'?: boolean;
  'read:interest-vs-capital-chart'?: boolean;
  'read:disbursement-vs-income-chart'?: boolean;
  'read:payment-user-resume'?: boolean;

  'read:client-report'?: boolean;
  'read:loan-report'?: boolean;
  'read:payment-report'?: boolean;
  'read:statement-report'?: boolean;
  'read:cash-report'?: boolean;
  'read:segment-report'?: boolean;
  'read:wallet-report'?: boolean;

  'upload:payment-attachment'?: boolean;
  'download:payment-attachment'?: boolean;
  'read:payment-attachment'?: boolean;
  'delete:payment-attachment'?: boolean;
  'read:payment'?: boolean;
  'create:payment'?: boolean;
  'update:payment'?: boolean;
  'delete:payment'?: boolean;

  'read:segment'?: boolean;
  'create:segment'?: boolean;
  'update:segment'?: boolean;
  'delete:segment'?: boolean;

  'read:user'?: boolean;
  'create:user'?: boolean;
  'update:user'?: boolean;
  'delete:user'?: boolean;

  'read:wallet'?: boolean;
  'create:wallet'?: boolean;
  'update:wallet'?: boolean;
  'delete:wallet'?: boolean;

  'read:stadistic-section'?: boolean;
  'read:payment-section'?: boolean;
  'read:loan-section'?: boolean;
  'read:segment-section'?: boolean;
  'read:cash-section'?: boolean;
  'read:wallet-section'?: boolean;
  'read:expense-section'?: boolean;
  'read:note-credit-debit-section'?: boolean;
  'read:client-section'?: boolean;
  'read:template-section'?: boolean;
  'read:user-section'?: boolean;

  'read:report-statement-section'?: boolean;
  'read:report-loan-section'?: boolean;
  'read:report-payment-section'?: boolean;
  'read:report-cash-section'?: boolean;
  'read:report-wallet-section'?: boolean;
  'read:report-segment-section'?: boolean;
  'read:report-client-section'?: boolean;

  'full:access'?: boolean;
};

export const permissionLabels = {
  cash: 'Cajas',
  'attachment-client': 'Clientes - Adjuntos',
  client: 'Clientes',
  'client-company': 'Clientes - Datos de Empresa',
  'client-reference': 'Clientes - Referencias',
  expense: 'Gastos',
  codebtor: 'Préstamos - Codeudor',
  'credit-debit-note': 'Nota de crédito/débito',
  'loan-expense': 'Préstamos - Otros gastos',
  'loan-setting': 'Préstamos - Configuraciones',
  loan: 'Préstamos',

  'general-chart': 'Gráficos - General',
  'interest-vs-capital-chart': 'Gráficos - Interés vs capital',
  'disbursement-vs-income-chart': 'Gráficos - Desembolso vs ingreso',
  
  'payment-user-resume': 'Informes - Resumen de pago',
  'client-report': 'Informes - Reporte de cliente',
  'loan-report': 'Informes - Préstamos',
  'payment-report': 'Informes - Pagos',
  'statement-report': 'Informes - Estado de cuenta',
  'cash-report': 'Informes - Caja',
  'segment-report': 'Informes - Rutas',
  'wallet-report': 'Informes - Billeteras',
  'payment-attachment': 'Pagos - Adjuntos',
  payment: 'Pagos',
  segment: 'Rutas',
  user: 'Usuarios',
  wallet: 'Billeteras',
  
  'stadistic-section': 'Sección de Estadísticas',
  'payment-section': 'Sección de Pagos',
  'loan-section': 'Sección de Préstamos',
  'segment-section': 'Sección de Rutas',
  'cash-section': 'Sección de Caja',
  'wallet-section': 'Sección de Billeteras',
  'expense-section': 'Sección de Gastos',
  'note-credit-debit-section': 'Sección de Notas de Crédito/Débito',
  'client-section': 'Sección de Clientes',
  'template-section': 'Sección de Plantillas',
  'user-section': 'Sección de Usuarios',
  'report-statement-section': 'Sección de Informes - Estado de cuenta',
  'report-loan-section': 'Sección de Informes - Préstamos',
  'report-payment-section': 'Sección de Informes - Pagos',
  'report-cash-section': 'Sección de Informes - Caja',
  'report-wallet-section': 'Sección de Informes - Billeteras',
  'report-segment-section': 'Sección de Informes - Rutas',
  'report-client-section': 'Sección de Informes - Clientes',
  access: 'Acceso total',
};

export type PermissionType = {
  value: keyof PermissionDictionaryType;
  description: string;
};

export type Permission = {
  permission_name: keyof PermissionDictionaryType;
  description: string;
  resource_server_name: string;
  resource_server_identifier: string;
};

export type AssignedPermissionType = Permission & {
  sources: {
    source_id: string;
    source_name: string;
    source_type: string;
  }[];
};

export type PermissionListType = PermissionType[];
export type AssignedPermissionListType = AssignedPermissionType[];
