import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {BackendDisplayableError, BackendDisplayableErrorList} from '../axios';

const useRequestHandler = ({onSuccess, onError}) => {
  const runCallback = useCallback(
    async <T>(callback: () => Promise<T>): Promise<T | false> => {
      try {
        const res = await callback();
        onSuccess(res);
        return res;
      } catch (e) {
        // console.log(e);
        onError(e);
        return false;
      }
    },
    [onSuccess, onError]
  );
  
  const runPromise = useCallback(
    async <T>(promise: Promise<T>): Promise<T | false> => {
      try {
        const res = await promise;
        onSuccess(res);
        return res;
      } catch (e) {
        // console.log(e);
        onError(e);
        return false;
      }
    },
    [onSuccess, onError]
  );

  return {runPromise, runCallback};
};

type UseDefaultRequestHandlerProps = {
  onSuccessMessage?: string;
  onErrorMessage?: string;
  navigateOnSuccess?: boolean;
  navigateTo?: string;
  onSuccess?: Function;
  onError?: Function;
};

export const useDefaultRequestHandler = ({
  onSuccessMessage = 'Registro guardado exitosamente',
  onErrorMessage = 'Por favor inténtelo nuevamente',
  navigateOnSuccess = true,
  navigateTo,
  onSuccess,
  onError,
}: UseDefaultRequestHandlerProps) => {
  const navigate = useNavigate();

  const _onSuccess = useCallback(
    (res) => {
      !!onSuccessMessage && toast.success(onSuccessMessage);
      if (navigateOnSuccess) {
        if (navigateTo) {
          return navigate({pathname: navigateTo});
        }
        navigate(-1);
      }

      if (onSuccess) {
        onSuccess(res);
      }
    },
    [navigate, navigateOnSuccess, navigateTo, onSuccess, onSuccessMessage]
  );

  const _onError = useCallback(
    (e) => {
      if (e instanceof BackendDisplayableError) {
        toast.error(e.message);
      } else if (e instanceof BackendDisplayableErrorList) {
        if (e.errors.length === 1) {
          return toast.error(e.errors[0]);
        }
        // Display errors as a list
        toast.error(
          React.createElement(
            'ul',
            {className: 'mb-0'},
            e.errors.map((error, index) => React.createElement('li', {key: index}, error))
          )
        );
      } else {
        // Display a default error message
        !!onErrorMessage && toast.error(onErrorMessage);
      }

      if (onError) {
        onError(e);
      }
    },
    [onErrorMessage, onError]
  );

  return useRequestHandler({
    onSuccess: _onSuccess,
    onError: _onError,
  });
};

export default useRequestHandler;
