/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

// import { useAuth0 } from '@auth0/auth0-react';
import {FC} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {App} from '../App';
import {AuthPage, Logout} from '../modules/auth';
import {ErrorsPage} from '../modules/errors/ErrorsPage';
import {PrivateRoutes} from './PrivateRoutes';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env;

const AppRoutes: FC = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='auth/*' element={<AuthPage />} />
          <Route path='/*' element={<PrivateRoutes />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export {AppRoutes};
