export const MIME_TYPES = {
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
};

export const FILE_EXTENSIONS = {
  jpeg: '.jpeg',
  jpg: '.jpeg',
  png: '.png',
  pdf: '.pdf',
};

export const MAX_FILE_SIZE = 1024 * 1024 * 5; // 5MB
export const MAX_FILENAME_LENGTH = 60;

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_DATE_FORMAT_WITH_TIME = 'yyyy-MM-dd HH:mm:ss';
