/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components';
import {useLayout, usePageData} from '../../core';
import {DefaultTitle} from './page-title/DefaultTitle';
import {TransparentButton} from '../../../../components/appButton/TransparentButton';
import {KTIcon} from '../../../helpers';
import {colors} from '../../../../colors';
import {CustomPopover} from '../../../../components/CustomPopover';
import ExchangeRateFormWrapper from '../../../../app/modules/exchange-rate/ExchangeRateForm';
import {usePermission} from '../../../../app/modules/auth/core/Permissions';
import {useAuth0} from '@auth0/auth0-react';
import useCustomTranslation from '../../../../modules/translation/hook/use-custom-translation';

const ToolBar = styled.div`
  background-color: #1e1e2d;
`;

const HeaderToolbar = () => {
  const {classes} = useLayout();
  const {pageActionElements, exchangeRate, setExchangeRate, settingId} = usePageData();
  const {hasPermission, currentUser} = usePermission();

  return (
    <ToolBar className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />
        <div className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0'>
          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Actions */}
            <div className='d-flex gap-5 align-items-center'>
            <div className='d-flex gap-3 align-items-center'>
              {hasPermission('update:loan-setting') ? (
                <CustomPopover
                  TogglerComponent={({onClick}) => (
                    <ExchangeRateButton onClick={onClick} exchangeRate={exchangeRate} />
                  )}
                  children={({close}) => (
                    <ExchangeRateFormWrapper
                      onSuccess={(exchangeRate) => {
                        close();
                        setExchangeRate(exchangeRate);
                      }}
                      exchangeRate={exchangeRate}
                      settingsId={settingId}
                    />
                  )}
                  placement='bottom-end'
                />
              ) : (
                <span className='text-muted'>Tasa de cambio: C$ {exchangeRate} </span>
              )}
              <span className='text-muted'>|</span>
              <CustomPopover
                TogglerComponent={({onClick}) => (
                  <CurrentUserButton onClick={onClick} currentUser={currentUser} />
                )}
                children={({close}) => <LogoutForm />}
                placement='bottom-end'
              />
              </div>
              {pageActionElements}
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </ToolBar>
  );
};

const ExchangeRateButton = ({onClick, exchangeRate}: {onClick: any; exchangeRate?: any}) => {
  return (
    <TransparentButton className='text-muted' onClick={onClick}>
      <KTIcon iconName='pencil' iconType='outline' color={'text-muted'} /> Tasa de cambio: C${' '}
      {exchangeRate}
    </TransparentButton>
  );
};

const CurrentUserButton = ({onClick, currentUser}: {onClick: any; currentUser?: any}) => {
  return (
    <TransparentButton className='text-muted' onClick={onClick}>
      <KTIcon iconName='user' iconType='outline' color={'text-muted'} /> {currentUser?.firstname}{' '}
      {currentUser?.lastname}
    </TransparentButton>
  );
};

const LogoutForm = () => {
  const {logout} = useAuth0();
  const {getTranslation} = useCustomTranslation();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <TransparentButton onClick={logoutWithRedirect} className='align-items-center d-flex gap-2'>
      <KTIcon iconName='exit-left' className='fs-1' />
      <span style={{color: colors.gray500}}>
      {getTranslation('GENERAL.LOGOUT')}
      </span>
    </TransparentButton>
  );
};

export {HeaderToolbar};
