import React, { HTMLInputTypeAttribute } from 'react';
import { CustomControlError } from './CustomControlError';
import { InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { useFormikContext } from 'formik';

export type CustomInputProps = {
  className?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  error?: string;
  name: string;
  type?: React.InputHTMLAttributes<HTMLInputTypeAttribute>['type'];
  isNumberFormatter?: boolean;
  inputGroup?: {
    prependText?: string;
    className?: string;
  };
  inputRef?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

const FormikInputGroupFormContainer = styled.div`
  flex-grow: 1;
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const PrependText = styled(InputGroup.Text).attrs({
  className: 'border-0 form-control-solid input-group-text w-unset z-index-1',
})`
  flex: unset;
  display: inline-block;
  z-index: 1;
  border-right: solid 1px !important;
  border-color: ${(props) =>
    props.error ? 'var(--bs-danger) !important' : 'var(--bs-gray-300) !important'};
  color: #5e6278;
  font-size: 1rem;
  font-weight: 600;
`;

export const CustomInput = ({
  className = 'form-control-solid',
  onBlur,
  value,
  onChange,
  placeholder,
  error,
  inputGroup,
  onFocus,
  inputRef,
  ...props
}: CustomInputProps) => {
  const formContext = useFormikContext();

  const commonProps = {
    onFocus: onFocus,
    className: `form-control ${className} ${error ? 'border-danger' : ''}`,
    placeholder: placeholder,
    value: value,
    onBlur: onBlur,
    name: props.name,
    disabled: props.disabled,
  };

  let input = props.isNumberFormatter ? (
    <NumericFormat
      decimalScale={2}
      thousandSeparator=','
      decimalSeparator='.'
      fixedDecimalScale={true}
      allowNegative={false}
      prefix={''}
      onValueChange={(values, source) => {
        const { floatValue } = values;
        formContext.setFieldValue(props.name, floatValue);
      }}
      {...commonProps}
    />
  ) : (
    <input
      ref={inputRef}
      onChange={onChange}
      {...commonProps}
      {...props}
      type={props.type === 'number' ? undefined : props.type}
      onInput={
        props.type === 'number'
          ? (event: any) => {
            const input = event!.target;
            input.value = input.value.replace(/[^0-9]/g, '');
          }
          : undefined
      }
    />
  );

  if (inputGroup) {
    return (
      <InputGroup className={inputGroup.className}>
        <PrependText error={error}>{inputGroup.prependText}</PrependText>
        <FormikInputGroupFormContainer>{input}
          {!!error && <CustomControlError name={props.name} error={error} />}
        </FormikInputGroupFormContainer>
      </InputGroup>
    );
  }

  return (
    <>
      {input}
      {!!error && <CustomControlError name={props.name} error={error} />}
    </>
  );
};
