import {useEffect, useState} from 'react';
import {KTIcon} from '../../_metronic/helpers';
import {usePermission} from '../modules/auth/core/Permissions';
import {ROUTE_UNAUTHORIZED} from '../modules/users/users.constants';
import {Navigate} from 'react-router-dom';
import useCustomTranslation from '../../modules/translation/hook/use-custom-translation';

export const Unauthorized = () => {
  const {getTranslation} = useCustomTranslation();
  const {getInitialRoute} = usePermission();
  const [redirectTo, setRedirectTo] = useState<string | undefined>();

  useEffect(() => {
    const initialRoute = getInitialRoute();
    if (initialRoute !== ROUTE_UNAUTHORIZED) {
      setRedirectTo(initialRoute);
    }
  }, [getInitialRoute]);

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <div className='container h-100'>
      <div className='row h-100 justify-content-center align-items-center'>
        <div className='col-md-6'>
          <div className='d-flex align-items-center text-gray-600'>
            <div>
              <h1 className='mb-4 text-gray-700'>{getTranslation('ACCESS_DENIED.MESSAGE')}</h1>
              <p className='mb-4'>{getTranslation('ACCESS_DENIED.CONTENT')}</p>
              <button
                className='btn btn-primary'
                onClick={() => {
                  document.location.reload();
                }}
              >
                {getTranslation('ACCESS_DENIED.REFRESH_PAGE')}
              </button>
            </div>
            <div className='icon mb-3'>
              <KTIcon iconType='outline' iconName='lock-3' className='fs-6x p-5 text-gray-500' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
