import {useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {addMiddleware, removeMiddleware} from '../../../setupAxios';

const useBearerToken = () => {
  const {getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    const appendToken = async (request) => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          request.headers['Authorization'] = `Bearer ${token}`;
        }
        return request;
      } catch (error) {
        // console.log(error);
      }
    };

    addMiddleware('request', appendToken);

    return () => {
      removeMiddleware('request', appendToken);
    };
  }, [getAccessTokenSilently]);
};

export default useBearerToken;
