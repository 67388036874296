import {InputGroup} from 'react-bootstrap';
import ReactDatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import {KTIcon} from '../../_metronic/helpers';
import {CustomControlError} from './CustomControlError';
import clsx from 'clsx';
import useCustomTranslation from '../../modules/translation/hook/use-custom-translation';
import {useEffect} from 'react';
import {useLang} from '../../_metronic/i18n/Metronici18n';

const loadLocale = (locale) => {
  return import(`date-fns/locale/${locale}`);
};

const setLocale = async (locale) => {
  try {
    let importedLocale;
    if (locale === 'en') {
      importedLocale = await loadLocale('en-US');
    } else {
      importedLocale = await loadLocale(locale);
    }
    registerLocale(locale, importedLocale.default);
    setDefaultLocale(locale);
  } catch (error) {
    console.error('invalid locale ' + locale);
  }
};

const CustomDatePickerContainer = styled(InputGroup)`
  flex-grow: 1;

  .react-datepicker-wrapper {
    flex: 1;
  }
`;

export type CustomDatePickerProps = {
  value?: Date;
  onChange?: (Date) => void;
  id?: string;
  name: string;
  error?: string;
  placeholder?: string;
  dateFormat?: string;
  withTime?: boolean;
  disabled?: boolean;
};

const CustomDatePicker = ({
  value,
  onChange,
  dateFormat = 'dd-MM-yyyy',
  placeholder = 'dd-mm-yyyy',
  id,
  withTime,
  error,
  name,
  disabled,
}: CustomDatePickerProps) => {
  const {getTranslation} = useCustomTranslation();
  const lang = useLang();

  useEffect(() => {
    setLocale(lang);
  }, [lang]);

  return (
    <CustomDatePickerContainer className='w-100 position-relative'>
      <ReactDatePicker
        className={clsx('form-control form-control-solid', {
          'border-danger': !!error,
        })}
        dateFormat={dateFormat + (withTime ? ' hh:mm a' : '')}
        autoComplete='off'
        placeholderText={placeholder}
        name={id}
        id={id}
        showTimeSelect={withTime}
        timeCaption={getTranslation('GENERAL.HOUR')}
        showMonthDropdown
        showYearDropdown
        todayButton={getTranslation('GENERAL.PLACEHOLDER.TODAY')}
        selected={value}
        disabled={disabled}
        onChange={onChange}
        locale='es'
      />
      <div className='position-absolute top-50 translate-middle-y' style={{right: 5}}>
        <KTIcon iconName='calendar' />
      </div>
      {!!error && <CustomControlError name={name} error={error} tooltipStyle={{right: 25}} />}
    </CustomDatePickerContainer>
  );
};

export default CustomDatePicker;
